.text {
    font-family: 'Inconsolata', monospace;
    color: #ffffff;
    padding-top: 6px;
    font-weight: 500;
    font-size: xx-large;
    padding-left: 20px;
}

.profile-information-container {
    display: flex;
    flex-direction: column;
}

.profile-information-text {
    font-family: 'Inconsolata', monospace;
    color: #ffffff;
    padding-top: 6px;
    font-weight: 500;
    font-size: medium;
    padding-left: 20px;
}

.line {
    height: 2px;
    border: 1px solid #ffffff;
    margin-top: 20px;
    width: 100%;
}

.editbtn-container {
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
}

.btn-edit {
    pointer-events: auto !important;
    background-color: #3399FF !important;
    border-width: 0 !important;
    border-radius: 10px !important;
    color: #ffffff !important;
    opacity: 100 !important;
    margin-top: 10px;
    font-size: 1rem !important;
}