/* 
* Colors:
* White: #ffffff;
* Grey:  #404040;
*/

.sideBarContainer {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.sideBar {
    background-color: #404040;
    width: 100%;
    position: absolute !important;
    right: 0;
    bottom: 0;
    top: 0;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 20px;
    border-color: rgba(255,255,255,0.25) !important;
    border: 3px;
    border-style: solid;
    box-shadow: 2px 2px 35px 0px rgba(0,0,0,0.65);
}

.set-height {
    height: 'calc(100% - 1px)'
}

.sideBarButton {
    pointer-events: auto !important;
    background-color: #404040;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 12px;
    border-radius: 15px !important;
    border: 3px !important;
    border-style: solid !important;
    border-color: rgba(255,255,255,0.25) !important;
    box-shadow: 2px 2px 35px 0px rgba(0,0,0,0.65);
}

.btn-primary {
    border-color: rgba(255,255,255,0.25) !important;
}

.sideBar-button-size {
    font-size: 45px;
    padding-top: 7px !important;
    padding: 5px;
}

.menu-close {
    color: #ffffff;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 12px;
    font-weight: 100;
    font-size: xx-large;
    pointer-events: auto !important;
    cursor: pointer;
}

.menu-title {
    font-family: 'Inconsolata', monospace;
    color: #ffffff;
    padding-top: 6px;
    font-weight: 900;
    font-size: xx-large;
}

.menu-logout-title {
    font-family: 'Inconsolata', monospace;
    color: #ffffff;
    padding-top: 6px;
    font-weight: 500;
    font-size: medium;
    padding-left: 20px;
}

.menu-logout-title:hover {
    border-bottom: 2px;
    color: #ffffff;
}

.menu-login-caret {
    padding-left: 20px;
    color: #ffffff;
    font-weight: 100;
    font-size: xx-large;
}

.menu-credit {
    font-family: 'Inconsolata', monospace;
    padding-left: 20px;
    color: #ffffff;
    font-weight: 700;
    font-size: xx-small;
}

.top-buffer {
    margin-top: 10px;
}

.login-switch {
    pointer-events: auto !important;
    cursor: pointer;
    font-family: 'Inconsolata', monospace;
    color: rgba(255, 255, 255, 0.5);
    padding-top: 6px;
    font-weight: 250;
    font-size: medium;
}

.left-right-space {
    margin-left: 7px;
    margin-right: 7px;
}

.left-space {
    margin-left: 20px;
}

.active {
    color: #ffffff !important;
}

.logout {
    pointer-events: auto !important;
}