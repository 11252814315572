html, body, #root, #parentRoot {margin:0;padding:0;height:100%;}

body {
    overflow: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-pointer-events {
    pointer-events: none
}

.container {
    height: 100%;
}

.fullHeight {
    height: 100%;
}

.test {
    height: 50%;
    width: 100%;
}

.componentContainer {
    position: relative;
    height: 100%;
    width: 100%;
}

.align-bot-left {
    display: inline-block;
    position: absolute !important;
    bottom: 0px;
    left: 0px;
    margin: 12px;
    background-color: #404040;
}

.btn-primary {
    background-color: #404040 !important;
    border-width: 0 !important;
    border-radius: 1px !important;
}

/* Create two classes and then give sideBar class a transition property */
@keyframes toggleMenu {
    from { max-height: 0%; width: 0%; }
    to { max-height: 100%; width: 100%; }
}

#content-desktop {display: block;}
@media screen and (max-width: 994px) {
#content-desktop {display: none; height: 0 !important;}
}