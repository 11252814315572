.liveFeedPosition {
    position: absolute !important;
    left: 0;
    bottom: 0;
    margin: 15px;
    box-shadow: 2px 2px 35px 0px rgba(0,0,0,0.65);
}

.liveFeedStyle {
    display: block;
    z-index: 1 !important;
    pointer-events: auto !important;
}