.coordinate-bar-style {
    font-family: 'Inconsolata', monospace;
    display: inline-block;
    margin-top: 15px;
    background-color: #404040;
    color: #ffffff;
    z-index: 1 !important;
    padding: 6px;
    font-weight: bold;
    border-radius: 20px;
    border-color: rgba(255,255,255,0.25) !important;
    border-width: 3px;
    border-style: solid;
    box-shadow: 2px 2px 35px 0px rgba(0,0,0,0.65);
}

.coordinate-bar-text {
    padding: 10px;
}